<template>
  <transition appear name="app-transition" mode="out-in">
    <div v-if="failedOrderCount && show" class="alert-card">
      <WeCard class="alert-danger">
        <div class="row align-items-center">
          <div class="col">
            <i class="fas fa-exclamation-triangle"></i>
            <span class="ml-2">{{
              $t("thereHaveBeenXIncorrectOrdersSinceYourLastVisit", {
                count: failedOrderCount,
              })
            }}</span>
          </div>
          <div class="col-auto">
            <!-- Show -->
            <router-link
              to="failed-orders"
              class="ml-auto btn btn-sm btn-outline-dark font-12 border-0"
              ><i class="fas fa-eye"></i
            ></router-link>
            <!-- ./Show -->

            <!-- Refresh -->
            <!-- <button
            class="btn btn-sm btn-outline-dark font-12 border-0 ml-2"
            v-on:click="onRefresh"
            v-tooltip.bottom="getDate"
          >
            <i v-bind:class="['fas fa-sync', loading ? 'fa-spin' : '']"></i>
          </button> -->
            <!-- ./Refresh -->

            <!-- Close -->
            <button
              class="btn btn-sm btn-outline-dark font-12 border-0 ml-2"
              v-on:click="onClose"
            >
              <i class="fas fa-times"></i>
            </button>
            <!-- ./Close -->
          </div>
        </div>
      </WeCard>
    </div>
  </transition>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "WeFailedOrderBadge",
  data() {
    return {
      updateDateTime: null,
      timer: null,
      failedOrderCount: 0,
      loading: false,
      show: true,
    };
  },
  methods: {
    ...mapActions("widget", ["getFailedOrder"]),
    onRefresh(showLoader = false) {
      this.loading = showLoader;
      this.setUpdateDateTime();
      this.getFailedOrderCount();
    },
    setUpdateDateTime() {
      this.updateDateTime = new Date(Date.now());
    },
    updateTimer() {
      this.timer = new Date(Date.now());
    },
    getFailedOrderCount() {
      const lastVisit = localStorage.getItem("lastFailedOrderVisit") || null;

      if (lastVisit) {
        this.getFailedOrder({
          date: lastVisit,
          onSuccess: (result) => {
            const locale = localization.locale.code;
            if (result && result.data && result.data.stats !== "error") {
              const resultCount = result.data.count;
              this.failedOrderCount = resultCount
                ? new Intl.NumberFormat(locale).format(resultCount)
                : 0;
            }
          },
          onFinish: () => {
            setTimeout(() => {
              this.loading = false;
            }, 550);
          },
        });
      }
    },
    onClose() {
      this.show = false;
    },
  },
  computed: {
    getDate() {
      const date = this.$filters.dateFormat(this.updateDateTime);
      return this.$t("latestUpdate") + ` <br /> ${date}`;
    },
  },
  watch: {
    timer: function (newVal) {
      let difference = helper.getTimerDiff(this.timer, this.updateDateTime);

      if (difference >= 1) {
        this.onRefresh();
      }
    },
  },
  mounted() {
    this.onRefresh(true);
  },
  created() {
    setInterval(this.updateTimer, 1000);
  },
};
</script>
<style lang="scss">
.alert-card {
  position: fixed;
  right: 15px;
  bottom: 75px;
  z-index: 1000;
}
</style>